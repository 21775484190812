'use client';

import { Button } from '@nextui-org/button';
import { Card, CardHeader, CardFooter, CardBody } from '@nextui-org/card';
import { Input } from '@nextui-org/input';
import { Divider } from '@nextui-org/react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense, useState } from 'react';

import Loading from '@/components/Loading/Loading';
import { Logo } from '@/components/icons';

function LoginPage() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [start, setStart] = useState(false);

  async function handleSubmit() {
    setStart(true);
    setErrorMsg('');

    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userName, password }),
    });

    if (response.ok) {
      const redirect_to = searchParams?.get('next');
      const redirect_query = searchParams?.get('query')
        ? '?' +
          searchParams?.get('query')?.replaceAll(',', '&').replaceAll(':', '=')
        : '';
      const link_to = `${redirect_to}${redirect_query}`;

      router.push(redirect_to ? link_to : '/');
    } else {
      setErrorMsg((await response.json()).error);
      console.log('hello');
      setStart(false);
      // Handle errors
    }
  }
  const [errorMsg, setErrorMsg] = useState('');

  return (
    <div className="flex h-[70vh] flex-col items-center justify-center bg-gray-100 dark:bg-gray-900">
      <Card className="w-full max-w-sm">
        <CardHeader>
          <div className="flex w-full flex-col items-center justify-center gap-3">
            {/* <p className='text-4xl font-bold text-center'>Welcome</p>
                        <Divider /> */}
            <p className="text-center">
              <Logo />
            </p>
            <Divider />
          </div>
        </CardHeader>
        <CardBody className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="username">Email</label>
            <Input
              required
              autoComplete="none"
              id="username"
              placeholder="username"
              type="username"
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="password">Password</label>
            <Input
              required
              id="password"
              placeholder="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key.toLowerCase() === 'enter') {
                  handleSubmit();
                }
              }}
            />
          </div>
        </CardBody>
        <CardFooter className="flex flex-col space-y-2">
          <p className="text-danger">{errorMsg}</p>
          <Button
            className="w-full"
            isLoading={start ? true : undefined}
            onClick={() => {
              handleSubmit();
            }}
          >
            Login
          </Button>
          <Link className="text-center text-sm" href="#" prefetch={false}>
            Forgot Password? Contact Mohamed Berkani
          </Link>
        </CardFooter>
      </Card>
      <div className="mt-4">
        <span className="text-sm">Don't have an account? </span>
        <Link className="text-sm text-blue-500" href="#" prefetch={false}>
          Contact Mohamed Brekani
        </Link>
      </div>
    </div>
  );
}

export default function page() {
  return (
    <Suspense fallback={<Loading />}>
      <LoginPage />
    </Suspense>
  );
}
